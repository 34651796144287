<template>
  <div>

    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'DirectRecharge'">
      <template v-slot:right-end>
      </template>
    </breadcrumb-base>

    <!--    Error List State-->
    <transition name="fade">
      <div class="py-6 flex w-full bg-img" v-if="errorFetching">
        <div
          class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
          <img src="@/assets/images/pages/svg_error.svg" :alt="$t('NoData')"
               class="mx-auto mb-4 max-w-full">
          <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('ErrorData') }}</h1>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div class="vs-row">
        <div class="vs-col px-4 py-3 flex md:w-full">
          <div class="vs-row w-full">
            <vs-input vs-w="12" class="p-3 w-full is-label-placeholder" icon-pack="feather" icon="icon-search"
                      :placeholder="$t('Search')" v-model="searchText"/>
            <div class="vs-col pl-3 py-3 flex md:w-full">
              <div class="inline-flex-wrap w-full" >
                <transition name="fade">
                  <div class="h-screen flex w-full bg-img" v-if="providers.length === 0 && !errorFetching">
                    <div
                      class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--                      <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
<!--                           class="mx-auto mb-4 max-w-full">-->
                      <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('NoData') }}</h1>
                    </div>
                  </div>
                </transition>
                <div class="vs-col md:w-1/3 mb-6"
                     :class="(index+1) % 3 === 0 ? 'pr-3' : 'pr-6'"
                     v-bind:key="index" v-for="(provider, index) in providers">
                  <vx-card class="cursor-pointer w-full" style="" @click="openRechargePopUp(provider)">
                    <p class="card-text">{{provider.provider}}</p>
                    <!--                    <p class="card-text-amount">{{product.AMOUNT | germanNumberFormat}}</p>-->
                  </vx-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div>
      <vx-modal v-if="showRechargeActive" @close="closedShowRecharge" component-class="modal-container-7">
        <template slot="header"></template>
        <template slot="body">
          <div class="px-6 py-3 flex flex-centered">
            <div class="vs-row justify-center col-mx-auto  w-full">
              <div class="vx-col text-center">
                <p class="card-text">{{selectedProvider.provider}}</p>
                <div class="p-2"></div>
              </div>
              <div class="flex flex-wrap w-full">
                <div class="w-full lg:w-1/4 p-5" v-for="(product, index) in selectedProvider.products">
                  <vx-card class="rounded-md"  v-bind:class="{ 'product-selected': selectedProductIndex == index }"  @click="setProductSelected(index)">
                    <div class="vx-row vs-align-center text-center">
                      <h1 class="mx-3 item-card-text">{{ product.amount | germanNumberFormat }}</h1>
                      <vs-spacer></vs-spacer>
<!--                      <h3 class="mx-3 item-card-profit">{{ $t('Profit') }} {{ calculateProfit(product.amount, selectedProvider.direct_recharge_discount)  | germanNumberFormat}}</h3>-->
                    </div>
                  </vx-card>
                </div>
              </div>
              <div class="modal-content py-2 px-10 flex-row flex justify-center" >
                <div class="flex flex-col md:w-1/2">

                  <vs-input size="large" class="pt-4 custom-input w-full"
                            type="number" v-validate="'required'"
                            validate-on="change"
                            :label="$t('MobileNumber')"
                            placeholder="0171234567"
                            v-model="mobileNo"/>
                  <vs-input size="large" class="pt-4 custom-input w-full"
                            type="number" v-validate="'required'"
                            validate-on="change"
                            :label="$t('ConfirmMobileNumber')"
                            placeholder="0171234567"
                            v-model="confirmMobileNo"/>
                </div>

                <div class="pt-6 my-auto">
                  <div class="vs-row flex justify-center px-4 items-center">
                    <vs-button size="large" class="ml-4  mr-4" color="danger" @click="closedShowRecharge()">{{
                        $t('Close')
                      }}
                    </vs-button>
                    <vs-button size="large" class="ml-2" color="primary" @click="purchaseCard()">{{ $t('Buy') }}</vs-button>
                  </div>
                </div>
              </div>
              <div class="p-2"></div>


              <div class="flex justify-evenly w-full card-billing-text">
                <div class="vx-row" v-if="activeUserInfo.mask_seller_discount != 1">
                  <p>{{ $t('BuyingPrice') }} <span class="card-billing-info">{{ (totalBill - profit )| germanNumberFormat }}</span></p>
                </div>
                <div class="vx-row" v-if="activeUserInfo.mask_seller_discount != 1">
                  <p>{{ $t('BuyingDiscount') }} <span class="card-billing-info">{{ selectedProvider.direct_recharge_discount }} %</span>
                  </p>
                </div>
                <div class="vx-row" >
                  <p>{{ $t('SellingPrice') }} <span class="card-billing-info">{{ totalBill | germanNumberFormat }}</span>
                  </p>
                </div>
                <div class="vx-row" v-if="activeUserInfo.mask_seller_discount != 1">
                  <p>{{ $t('Profit') }} <span class="card-billing-info">{{ profit | germanNumberFormat }}</span></p>
                </div>
              </div>

            </div>
          </div>
        </template>
        <template slot="footer"></template>
      </vx-modal>

    </div>
    <vx-modal v-if="showPurchaseDataModal" component-class="modal-container-fit">
      <template slot="body">
        <div v-if="purchaseData">
          <div style="max-height: 500px; overflow-y: auto;">
                  <div class="text-center status-card" v-if="!purchaseData.is_checked">
                    <div class="status-image origin" style="transform-origin: center center; margin: auto;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="201.221" height="281.309" viewBox="0 0 201.221 281.309">
                              <g id="Group_5693" data-name="Group 5693" transform="translate(-168.643 0.5)">
                                <path id="Path_9241" data-name="Path 9241" d="M323.038,128.331a8.76,8.76,0,0,0,6.2-2.593l16.048-16.013a43.621,43.621,0,0,0,12.789-30.957V72.76a8.759,8.759,0,0,0-8.76-8.76H296.759A8.759,8.759,0,0,0,288,72.76v6.027a43.484,43.484,0,0,0,12.824,30.957l16.013,16.013A8.759,8.759,0,0,0,323.038,128.331ZM305.659,81.519l34.758.07a26.1,26.1,0,0,1-7.533,15.767l-9.846,9.828-9.828-9.9A26.03,26.03,0,0,1,305.659,81.519Z" transform="translate(-53.785 -28.961)" fill="#f7c11b" stroke-width="1" />
                                <path id="Path_9242" data-name="Path 9242" d="M359.352,260.287H349.341V223.366a89.44,89.44,0,0,0-26.389-63.69L303.43,140.154l19.522-19.521a89.44,89.44,0,0,0,26.389-63.69V20.022h10.011a10.011,10.011,0,1,0,0-20.022h-180.2a10.011,10.011,0,1,0,0,20.022h10.011V56.863a90.68,90.68,0,0,0,26.349,63.73l19.562,19.561-19.562,19.561a90.68,90.68,0,0,0-26.349,63.73v36.841H179.154a10.011,10.011,0,1,0,0,20.022h180.2a10.011,10.011,0,1,0,0-20.022Zm-30.033,0H209.187V223.446a70.557,70.557,0,0,1,20.482-49.575l26.65-26.629a10.012,10.012,0,0,0,0-14.175l-26.65-26.629a70.557,70.557,0,0,1-20.482-49.575V20.022H329.319V56.943A69.539,69.539,0,0,1,308.8,106.477l-26.61,26.589-.018.018a10.013,10.013,0,0,0,0,14.14l.018.018,26.61,26.589a69.539,69.539,0,0,1,20.522,49.535Z" fill="#f7c11b" stroke-width="1" />
                                <path id="Path_9243" data-name="Path 9243" d="M329.24,333.089a8.734,8.734,0,0,0-12.4-.017l-16.013,16.012A43.484,43.484,0,0,0,288,380.041v6.027a8.759,8.759,0,0,0,8.76,8.76h52.558a8.759,8.759,0,0,0,8.76-8.76v-6.009A43.621,43.621,0,0,0,345.288,349.1Zm-23.581,44.219a26.03,26.03,0,0,1,7.551-15.767l9.828-9.9,9.846,9.828a26.105,26.105,0,0,1,7.533,15.767Z" transform="translate(-53.785 -149.557)" fill="#f7c11b" stroke-width="1" />
                              </g>
                            </svg>
                    </div>
                    <h4>{{$t('TransactionPending')}}</h4>
                  </div>
                  <div class="text-center status-card" v-if="purchaseData.is_checked && purchaseData.status == 1">
<!--                    <div class="status-image">-->
<!--                      <svg style="margin: auto;display: block" xmlns="http://www.w3.org/2000/svg" width="289.944" height="276.917" viewBox="0 0 289.944 276.917">-->
<!--                        <path id="Path_9271" data-name="Path 9271" d="M570.65,427.6l-24-31.728.774-39.782a11.32,11.32,0,0,0-8.041-11.057L501.3,333.5l-22.722-32.663a11.327,11.327,0,0,0-13.022-4.231l-37.581,13.079-37.586-13.079a11.326,11.326,0,0,0-13.02,4.231L354.646,333.5l-38.081,11.535a11.32,11.32,0,0,0-8.041,11.057l.774,39.782-24,31.728a11.323,11.323,0,0,0,0,13.666L309.3,473l-.774,39.782a11.322,11.322,0,0,0,8.041,11.057l38.081,11.537,22.722,32.661a11.324,11.324,0,0,0,13.02,4.229l37.586-13.077,37.583,13.077a11.324,11.324,0,0,0,13.02-4.229L501.3,535.371l38.081-11.535a11.323,11.323,0,0,0,8.041-11.057L546.646,473l24-31.728a11.323,11.323,0,0,0,0-13.666Zm-44.435,34.855a11.334,11.334,0,0,0-2.292,7.055l.682,35.132-33.64,10.189a11.324,11.324,0,0,0-6.015,4.371l-20.063,28.842L431.694,536.5a11.285,11.285,0,0,0-7.445,0l-33.193,11.547L370.994,519.2a11.323,11.323,0,0,0-6.016-4.371l-33.641-10.189.683-35.132a11.324,11.324,0,0,0-2.292-7.052l-21.2-28.024,21.2-28.025a11.308,11.308,0,0,0,2.292-7.052l-.683-35.132,33.641-10.189a11.336,11.336,0,0,0,6.016-4.371l20.063-28.844,33.2,11.549a11.329,11.329,0,0,0,7.443,0l33.193-11.549,20.066,28.844a11.353,11.353,0,0,0,6.013,4.371l33.64,10.189-.682,35.132a11.321,11.321,0,0,0,2.292,7.05l21.2,28.027ZM486.26,392.907a11.329,11.329,0,0,1,0,16.018l-67.04,67.038a11.331,11.331,0,0,1-16.018,0l-33.519-33.517A11.326,11.326,0,1,1,385.7,426.428l25.511,25.509,59.03-59.03a11.325,11.325,0,0,1,16.018,0Zm0,0" transform="translate(-283 -295.977)" fill="#24bc00"/>-->
<!--                      </svg>-->
<!--                    </div>-->
<!--                    <h4>{{$t('TransactionSuccessful')}}</h4>-->

                    <div v-if="purchaseData">
                      <receipt-print
                        v-on:close="closeModal()"
                        ref="printCard"
                        :print-report-type="3" :purchase-data="purchaseData"></receipt-print>
                    </div>
                  </div>
                  <div class="text-center status-card" v-if="purchaseData.is_checked && purchaseData.status == 0">
                    <div class="status-image">
                            <svg style="margin: auto;display: block" xmlns="http://www.w3.org/2000/svg" width="241.43" height="241.43" viewBox="0 0 241.43 241.43">
                              <g id="Group_5694" data-name="Group 5694" transform="translate(-284 -284)">
                                <path id="Path_2360" data-name="Path 2360" d="M404.715,284C338.242,284,284,338.251,284,404.715S338.251,525.43,404.715,525.43,525.43,471.178,525.43,404.715,471.178,284,404.715,284Zm0,227.284A106.569,106.569,0,1,1,511.284,404.715,106.689,106.689,0,0,1,404.715,511.284Zm0,0" fill="#ff000d"/>
                                <path id="Path_2361" data-name="Path 2361" d="M435,456.219A21.219,21.219,0,1,0,456.219,435,21.244,21.244,0,0,0,435,456.219Zm21.219-7.073a7.073,7.073,0,1,1-7.073,7.073,7.082,7.082,0,0,1,7.073-7.073Zm0,0" transform="translate(-79.797 -79.797)" fill="#ff000d"/>
                                <path id="Path_2362" data-name="Path 2362" d="M576.219,435a21.219,21.219,0,1,0,21.219,21.219A21.244,21.244,0,0,0,576.219,435Zm0,28.293a7.073,7.073,0,1,1,7.073-7.073,7.082,7.082,0,0,1-7.073,7.073Zm0,0" transform="translate(-143.212 -79.797)" fill="#ff000d"/>
                                <path id="Path_2363" data-name="Path 2363" d="M431.22,377.072a7.069,7.069,0,0,0-10,0l-14.146,14.146a7.072,7.072,0,0,0,10,10l14.146-14.146a7.069,7.069,0,0,0,0-10Zm0,0" transform="translate(-63.943 -48.09)" fill="#ff000d"/>
                                <path id="Path_2364" data-name="Path 2364" d="M627.074,377.072a7.072,7.072,0,0,0-10,10l14.146,14.146a7.072,7.072,0,0,0,10-10Zm0,0" transform="translate(-174.92 -48.09)" fill="#ff000d"/>
                                <path id="Path_2365" data-name="Path 2365" d="M442.073,555a7.073,7.073,0,0,0,0,14.146,49.568,49.568,0,0,1,49.512,49.512,7.073,7.073,0,1,0,14.146,0A63.731,63.731,0,0,0,442.073,555Zm0,0" transform="translate(-79.797 -143.212)" fill="#ff000d"/>
                              </g>
                            </svg>
                    </div>
                    <h4>{{$t('TransactionFailed')}}</h4>
                  </div>
          </div>
          <div class="vs-row flex justify-center mt-3">
            <vs-button v-if="!purchaseData.is_checked || purchaseData.status != 1" class="ml-4 mt-3" color="primary" @click="closeModal()">{{ $t('Close') }}</vs-button>
          </div>
        </div>
      </template>
    </vx-modal>
  </div>
</template>

<script>
import VxModal from '@/layouts/components/custom/VxModal'
import ReceiptPrint from '@/views/components/ReceiptPrint'

export default {
  name: 'DirectRecharge',
  components: {
    ReceiptPrint,
    VxModal,
  },
  data() {
    return {
      mobileNo: '',
      confirmMobileNo: '',
      showRechargeActive: false,
      selectedProvider: null,
      selectedProductIndex: null,
      errorFetching: false,
      filterAccountStatus: [],
      searchText: '',
      selectedAlpha: null,
      selectedAlphaCurrentX: 1,
      alpha: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'Direct Recharge', i18n: 'DirectRecharge', active: true },
      ],
      purchaseData: null,
      showPurchaseDataModal: false,
    }
  },
  computed: {
    providers() {
      return this.$store.state.directRecharge.providers.filter((c) =>
        c.provider.toLowerCase().startsWith(this.searchText.toLowerCase())
      )
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
  },
  methods: {
    closeModal() {
      this.showPurchaseDataModal = false
      this.closedShowRecharge()
      this.fetchAllProducts()
    },
    clearSelectedAlpha() {
      this.selectedAlpha = null
      this.selectedAlphaCurrentX = 1

      this.searchText = ''
    },
    pageChanged(newIndex) {
      if (isNaN(newIndex)) {
        this.selectedAlpha = newIndex
        this.selectedAlphaCurrentX = newIndex
        this.searchText = this.selectedAlphaCurrentX
      }
    },
    calculateBill() {
      if (this.selectedProductIndex == null) {
        this.totalBill = 0
        this.profit = 0
      } else {
        this.totalBill = this.selectedProvider.products[this.selectedProductIndex].amount
        this.profit = ((this.totalBill) * (Number(this.selectedProvider.direct_recharge_discount) / 100)).toFixed(2)
      }
    },
    calculateProfit(amount, commission) {
      return (Number(amount) * (Number(commission) / 100)).toFixed(2)
    },
    setProductSelected(index) {
      if (this.selectedProductIndex == index) {
        this.selectedProductIndex = null
      } else {
        this.selectedProductIndex = index
      }
      this.calculateBill()
    },
    openRechargePopUp(p) {
      this.selectedProvider = p
      this.selectedProductIndex = null
      this.calculateBill()
      this.showRechargeActive = true
    },
    closedShowRecharge() {
      this.selectedProvider = null
      this.mobileNo = ''
      this.showRechargeActive = false
      this.selectedProductIndex = null
    },
    purchaseCard() {
      if (this.mobileNo.length < 5) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: this.$t('FillMobileError'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }
      if (this.mobileNo != this.confirmMobileNo) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: this.$t('MobileNotMatched'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }
      if (this.selectedProductIndex == null) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Please select the product',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }
      const data = {
        'DALAN': this.selectedProvider.products[this.selectedProductIndex].dalan,
        'LABEL': this.selectedProvider.products[this.selectedProductIndex].label,
        'AMOUNT': this.selectedProvider.products[this.selectedProductIndex].amount,
        'PROVIDER': this.selectedProvider.provider,
        'COMMISSION': this.selectedProvider.products[this.selectedProductIndex].commission,
        'USERFEE': this.selectedProvider.products[this.selectedProductIndex].user_fee,
        'mobile': this.mobileNo,
        'direct_recharge_id': this.selectedProvider.products[this.selectedProductIndex].id,
      }
      this.showRechargeActive = false
      this.$vs.loading()
      this.$store.dispatch('directRecharge/rechargeMobileNo', data)
        .then((data) => {
          this.$vs.loading.close()
          if (data.message_type == 0) {
            this.$vs.notify({
              position: 'top-center',
              title: 'Error',
              text: data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
            })
          } else {
            this.purchaseData = data.transaction_details
            this.showPurchaseDataModal = true
            this.closedShowRecharge()
            if (!this.purchaseData.is_checked) {
              this.checkTransaction()
            } else if (this.purchaseData.is_checked && this.purchaseData.status == 1) {
              this.checkPrintCardPop()
            }
          }
        })
        .catch((error) => {
          this.closedShowRecharge()
          this.$vs.loading.close()
          let msg = ''
          try {
            msg = error.response.data.data.TEXT
          } catch (e) {
            try {
              msg = error.response.data.message
            } catch (e) {

            }
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },

    checkTransaction() {
      const data = {
        'direct_recharge_history_id': this.purchaseData.id,
      }
      this.$vs.loading()
      this.$store.dispatch('directRecharge/checkRechargeTransaction', data)
        .then((data) => {
          this.$vs.loading.close()
          this.purchaseData = data.check_response
          this.showPurchaseDataModal = true
          this.closedShowRecharge()
          if (this.purchaseData.is_checked && this.purchaseData.status == 1) {
            this.checkPrintCardPop()
          }
        })
        .catch((error) => {
          this.closedShowRecharge()
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },

    checkPrintCardPop() {
      const showPrintPop = localStorage.getItem('show_print_pop') || 'false'
      if (showPrintPop != 'true') {
        setTimeout(() => {
          this.$refs.printCard.printCard()
        }, 1000)
      }
    },
    fetchAllProducts() {
      this.$vs.loading()
      this.$store.dispatch('directRecharge/fetchProducts')
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          let msg = ''
          try {
            msg = error.response.data.message
          } catch (err) {
            msg = ''
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    }
  },
  created() {
    this.fetchAllProducts();
  },
}
</script>

<style>
.modal-container {
  border-radius: 30px !important;
}
.card-image {
  max-height: 48px;
  object-fit: cover;
  margin: auto;
}
.modal-content {

}
.card-text {
  /*max-height: 48px;*/
  object-fit: cover;
  margin: auto;
  font-size: 22px;
  font-weight: bold;
}
.card-text-amount {
  /*max-height: 48px;*/
  object-fit: cover;
  margin: auto;
  font-size: 18px;
}
.custom-input > .vs-con-input > input {
  font-size: 1.4rem !important;
}
.custom-input > .vs-con-input > * {
  font-size: 1.4rem !important;
}

.card-billing-text {
  font-size: 20px;
  font-weight: bold  !important;;
}

.card-billing-info {
  font-weight: normal !important;
}
.status-image > svg {
  width: 100px;
  height: 100px;
}
.status-card > h4 {
  margin-top: 24px;
}

.origin {
  width: 100px;
  height: 100px;
  position: relative;
  -webkit-animation: spin 6s cubic-bezier(0.8, 0, 0.2, 1) infinite;
  animation: spin 6s cubic-bezier(0.8, 0, 0.2, 1) infinite;
}
.origin > svg {
  width: 100px;
  height: 100px;
}
@-webkit-keyframes spin {
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.item-card-text {
  font-size: 28px;
  font-weight: bolder;
}
.item-card-profit {
  text-align: center;
  font-size: 14px;
  font-weight: bolder;
  margin: auto;
  color: #2c2c2c;
}
.product-selected {
  background-color: #44D7B6 !important;
  color: #ffffff !important;
}
.product-selected .item-card-profit {
  color: #ffffff !important;
}
.product-selected .item-card-text {
  color: #ffffff !important;
}
</style>
